import React, { useState, useEffect } from "react";
import {
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  eachDayOfInterval,
  format,
  isSameMonth,
  isSameDay,
  getYear,
  getMonth
} from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import "./calendarGrid.css";
import EventRow from '../eventTable/eventRow';

const Calendar = ({ events, onDeleteEvent, onSendEvent, onEditEvent, updateEventField, setUserPrivileges }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [dayView, setDayView] = useState({});

  const startDate = startOfWeek(startOfMonth(currentDate));
  const endDate = endOfWeek(endOfMonth(currentDate));
  const days = eachDayOfInterval({ start: startDate, end: endDate });

  const currentYear = getYear(currentDate);
  const currentMonth = getMonth(currentDate) + 1;
  const monthKey = `${currentYear}-${currentMonth.toString().padStart(2, "0")}`;

  useEffect(() => {
    const filteredEvents = events[currentYear]?.months?.[monthKey]?.filter((event) =>
      isSameDay(
        new Date(new Date(`${event.month}-${event.day}`).setDate(new Date(`${event.month}-${event.day}`).getDate() + 1)),
        dayView
      )
    ) || [];
    setSelectedEvents(filteredEvents);
  }, [events, dayView]); // Atualiza sempre que `events` ou `dayView` mudar


  const handlePrevMonth = () => {
    setCurrentDate((prev) => new Date(prev.getFullYear(), prev.getMonth() - 1, 1));
  };

  const handleNextMonth = () => {
    setCurrentDate((prev) => new Date(prev.getFullYear(), prev.getMonth() + 1, 1));
  };

  const handleDayClick = (day) => {
    const eventList =
      events[currentYear]?.months?.[monthKey]?.filter((event) =>
        isSameDay(
          new Date(new Date(`${event.month}-${event.day}`).setDate(new Date(`${event.month}-${event.day}`).getDate() + 1)),
          day
        )
      ) || [];

    setSelectedEvents(eventList);
    setModalOpen(true);
    setDayView(day);
  };


  const handleClassName = (event) => {
    let className = "";
    if (event.active && event.isValid && event.sended) {
      className = 'sended-event';
    } else if (event.active && event.isValid && !event.sended) {
      className = 'inactive-event';
    } else if (event.active && !event.isValid) {
      className = 'invalid-event';
    }
    return className;
  }

  return (
    <div>
      <div className="monthBanner">
        <h2>
          {format(currentDate, "MMMM yyyy", { locale: ptBR })
            .charAt(0)
            .toUpperCase() +
            format(currentDate, "MMMM yyyy", { locale: ptBR }).slice(1)}
        </h2>
      </div>
      <div className="scheduleGrid">
        <div className="calendar">
          <div className="days-grid">
            {["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"].map((day) => (
              <div key={day} className="weekday">
                {day}
              </div>
            ))}

            {days.map((day) => {
              const dayCopy = new Date(day); // Cria uma cópia para evitar modificar o original
              const dayEvents = events[currentYear]?.months?.[monthKey]?.filter((event) => {
                const dayEvent = new Date(new Date(`${event.month}-${event.day}`).setDate(new Date(`${event.month}-${event.day}`).getDate() + 1));
                return isSameDay(dayEvent, dayCopy);
              }) || [];

              // Definição dos eventos a serem renderizados
              const displayedEvents = dayEvents.slice(0, 2); // Pega os dois primeiros eventos
              const extraEvent = dayEvents.length > 2 ? dayEvents.find(event => event.scale) : null;
              const moreEventsCount = dayEvents.length - (extraEvent ? 3 : 2);

              return (
                <div
                  key={day.toISOString()} // Usa `toISOString()` para evitar chaves duplicadas
                  className={`day ${!isSameMonth(dayCopy, currentDate) ? "disabled" : ""}`}
                  onClick={() => handleDayClick(dayCopy)}
                  style={{ cursor: dayEvents.length > 0 ? "pointer" : "default" }}
                >
                  <span>{format(dayCopy, "d")}</span>

                  {/* Renderiza os primeiros dois eventos */}
                  {displayedEvents.map((event, index) => (
                    <div key={index} className={"event " + handleClassName(event)} onClick={() => handleDayClick(dayCopy)}>
                      {event.title}
                    </div>
                  ))}

                  {/* Renderiza o terceiro evento apenas se tiver scale */}
                  {extraEvent && (
                    <div key={"extra"} className={"event " + handleClassName(extraEvent)} onClick={() => handleDayClick(dayCopy)}>
                      {extraEvent.title}
                    </div>
                  )}

                  {/* Indica que há mais eventos não exibidos */}
                  {moreEventsCount > 0 && (
                    <div className="event more-events" onClick={() => handleDayClick(dayCopy)}>
                      +{moreEventsCount} mais
                    </div>
                  )}
                </div>
              );
            })}


          </div>

          {/* Botões de navegação flutuantes */}
          <div className="nav-buttons">
            <button onClick={handlePrevMonth}>{"<"}</button>
            <button onClick={handleNextMonth}>{">"}</button>
          </div>
        </div>
      </div>

      {/* Modal para exibir eventos do dia */}
      {modalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              {`${format(dayView, "dd 'de' MMMM 'de' yyyy", { locale: ptBR })}`}
            </div>
            <div className="modal-body">
              {selectedEvents.map((event, index) => (
                <EventRow
                  key={event.id}
                  event={event}
                  onDeleteEvent={onDeleteEvent}
                  onSendEvent={onSendEvent}
                  onEditEvent={onEditEvent}
                  updateEventField={updateEventField}
                  setUserPrivileges={setUserPrivileges}
                />
              ))}
            </div>
            <div className="modal-footer">
              <button className="modal-close" onClick={() => setModalOpen(false)}>Fechar</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Calendar;
