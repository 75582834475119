import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// Configuração do Firebase
const firebaseConfig1 = {
  apiKey: "AIzaSyD71aajbuopH-VZ9gmo5poOFyp1Dhevw2s",
  authDomain: "agendaiasd-ccb49.firebaseapp.com",
  projectId: "agendaiasd-ccb49",
  storageBucket: "agendaiasd-ccb49.firebasestorage.app",
  messagingSenderId: "372111701662",
  appId: "1:372111701662:web:8a5da0b08c1140779d6d8f",
  measurementId: "G-8YG9YKHNZ7"
};

// Inicializa o aplicativo Firebase
const app = firebase.initializeApp(firebaseConfig1, 'app1');

// Inicializa o Firestore e Auth
const db = app.firestore();
const auth = app.auth();

// Exporta o Firestore, Auth e App
export { db, auth, app };
